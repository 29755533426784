import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import { observer } from 'mobx-react-lite'

import { useRootStore } from '../../hooks/useStore'


const { TabPane } = Tabs

const Sections = observer(() => {
  const {
    skillStore: { sectionsRequest, selectSection, fetchInitData }
  } = useRootStore()

  useEffect(() => {
    fetchInitData()
  }, [fetchInitData])

  const onTabClick = (tabKey) => {
    selectSection(tabKey)
  }

  // console.log(sectionsRequest.data?.items)

  // if (!sectionsRequest?.data?.count) return null

  return (
    <Tabs type="card" onTabClick={onTabClick}>
      {sectionsRequest.data?.items?.map(section => (
        <TabPane tab={section.name} key={section.id} />
      ))}
    </Tabs>
  )
})

export default React.memo(Sections)
