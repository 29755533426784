/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
import React, { useContext, useState, useRef, useEffect, useMemo } from 'react'
import { Table, Input, Form } from 'antd'
// import faker from 'faker'
import { observer } from 'mobx-react-lite'

import { useRootStore } from '../../hooks/useStore'


const EditableContext = React.createContext(null)

// const EmployeeTable = () => {
//   return (
//     <Table
//       columns={columns}
//       dataSource={data}
//       scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 320px)' }}
//       bordered
//     />
//   )
// }

// const originData = []

// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`
//   })
// }

// eslint-disable-next-line no-unused-vars
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

// eslint-disable-next-line no-unused-vars
const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      // console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} maxLength={1} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

const EmployeeTable = observer(() => {
  const {
    skillStore: { tableDataSource, columnsData, handleSaveRow }
  } = useRootStore()

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  const tableColumns = useMemo(() => {
    return columnsData.map((col) => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          handleSave: handleSaveRow
        })
      }
    })
  }, [columnsData, handleSaveRow])

  if (!tableColumns || !tableDataSource) return null

  return (
    <Table
      components={components}
      rowClassName={() => 'editable-row'}
      bordered
      dataSource={tableDataSource}
      columns={tableColumns}
      scroll={{ scrollToFirstRowOnChange: true, y: 'calc(100vh - 320px)' }}
    />
  )
})

export default EmployeeTable
