import { makeAutoObservable, autorun, runInAction } from 'mobx'

import { login } from '../api/auth'
import { setToken } from '../services/httpClient'
import { getAuthToken } from '../utils/localStorage'

import ApiRequest from './ApiRequest'


export default class AuthStore {
  loginRequest = new ApiRequest({
    apiFunction: login
  })

  constructor(rootStore) {
    this._rootStore = rootStore
    makeAutoObservable(this)

    autorun(() => {
      const token = this.loginRequest.data?.token
      if (token) {
        setToken(token)
        runInAction(() => {
          this._rootStore.uiStore.isLoginFormVisible = false
          this._rootStore.skillStore.fetchInitData()
        })
      }
    })
  }

  login = (username, password) => {
    this.loginRequest.send({ username, password })
  }

  getToken = () => getAuthToken() || undefined
}
