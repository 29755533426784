import { makeAutoObservable } from 'mobx'


export default class SkillTableRow {
  key = null
  skillName
  cells = []

  constructor(store, key, skillName, cells) {
    makeAutoObservable(this, {
      key: false,
      skillName: false
    })

    this.store = store

    this.key = key
    this.skillName = skillName
    this.cells = cells
  }
}
