import React from 'react'

import 'antd/dist/antd.css'
import './App.css'

import SkillForm from './components/common/SkillForm'
import LoginForm from './components/common/LoginForm'
import { RootStoreProvider } from './context/RootStoreContext'
import Competencies from './pages/Competencies'


const App = () => {
  return (
    <RootStoreProvider>
      <LoginForm />
      <SkillForm />
      <Competencies />
    </RootStoreProvider>
  )
}

export default App
