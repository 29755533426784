import AuthStore from './AuthStore'
import UiStore from './UiStore'
import SkillStore from './SkillStore'


export default class RootStore {
  constructor() {
    this.authStore = new AuthStore(this)
    this.uiStore = new UiStore(this)
    this.skillStore = new SkillStore(this)
  }
}

// root store init
// holds a reference to the store (singleton)
export const rootStore = new RootStore()
