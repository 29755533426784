import React, { useEffect } from 'react'
import { Button, Typography, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useRootStore } from '../../hooks/useStore'
import { Layout } from '../../components/ui/Layout'
import EmployeeTable from '../../components/common/EmployeeTable'
// eslint-disable-next-line no-unused-vars
import Sections from '../../components/common/Sections'
import Filters from '../../components/common/Filters'

import { Technologies, ActionsBar, TabsContainer, TableContainer } from './styled'


const { Title } = Typography
const { Search } = Input

const Competencies = () => {
  const {
    uiStore: { toggleSkillForm, showLoginForm },
    authStore: { getToken },
    skillStore: { searchSkills }
  } = useRootStore()

  useEffect(() => {
    if (!getToken()) showLoginForm()
  }, [getToken, showLoginForm])

  const onSearch = (text) => {
    searchSkills(text)
  }

  return (
    <Layout>
      <Technologies>
        <Title level={2}>Технологии</Title>
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleSkillForm}>
          НОВАЯ ТЕХНОЛОГИЯ
        </Button>
      </Technologies>

      <ActionsBar>
        <Search placeholder="Поиск" onSearch={onSearch} enterButton />
        <Filters />
      </ActionsBar>

      <TabsContainer></TabsContainer>
      <Sections />
      <TableContainer>
        <EmployeeTable />
      </TableContainer>
    </Layout>
  )
}

export default Competencies
