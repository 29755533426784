/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react'
import { Button, Popover, Select, Form } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'

import { useRootStore } from '../../hooks/useStore'


const { Option } = Select

const Filters = observer(() => {
  const {
    skillStore: { employeesRequest, departmentsRequest, setFilters }
  } = useRootStore()

  const [form] = Form.useForm()

  const content = useMemo(() => {
    const onFinish = (values) => {
      setFilters(values)
    }
    return (
      <div style={{ width: 300 }}>
        <Form
          layout="vertical"
          form={form}
          size="small"
          onFinish={onFinish}
          initialValues={{ employees: [], empDepartments: [], skillDepartments: [] }}
        >
          <Form.Item label="Сотрудники" name="employees">
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              placeholder="Выберите сотрудников"
              allowClear
              optionFilterProp="key"
            >
              {employeesRequest.data?.items.map(employee => (
                <Option key={employee.username} value={employee.id}>
                  {employee.username}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Сотрудники по отделам" name="empDepartments">
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              placeholder="Выберите отделы"
              allowClear
              optionFilterProp="key"
            >
              {departmentsRequest.data?.items.map(department => (
                <Option key={department.name} value={department.id}>
                  {department.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Навыки по отделам" name="skillDepartments">
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              placeholder="Выберите отделы"
              allowClear
              optionFilterProp="key"
            >
              {departmentsRequest.data?.items.map(department => (
                <Option key={department.name} value={department.id}>
                  {department.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Применить
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }, [employeesRequest.data, departmentsRequest.data, form, setFilters])

  return (
    <Popover placement="bottomRight" content={content} trigger="click">
      <Button icon={<FilterFilled />} onClick={e => e.preventDefault()} />
    </Popover>
  )
})

export default React.memo(Filters)
