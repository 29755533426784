import React from 'react'
import { Form, Input, Modal, notification } from 'antd'
import { observer } from 'mobx-react-lite'

import { useRootStore } from '../../hooks/useStore'


const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
}

const LoginForm = observer(() => {
  const {
    uiStore: { isLoginFormVisible },
    authStore: { login }
  } = useRootStore()

  const [form] = Form.useForm()

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields()
        login(values.username, values.password)
      })
      .catch((info) => {
        notification['error']({
          message: 'Validate Failed',
          description: info
        })
      })
  }

  return (
    <Modal title="Login" visible={isLoginFormVisible} okText="Войти" onOk={handleOk}>
      <Form {...layout} form={form} name="basic">
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  )
})

export default LoginForm
