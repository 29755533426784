import { useContext } from 'react'

import { RootStoreContext } from '../context/RootStoreContext'


export function useRootStore() {
  const context = useContext(RootStoreContext)

  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider')
  }

  return context
}
