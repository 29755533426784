import React, { createContext } from 'react'

import { rootStore } from '../stores/RootStore'


export const RootStoreContext = createContext(undefined)

export function RootStoreProvider({ children }) {
  return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>
}
