/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React from 'react'
import { makeAutoObservable, autorun, runInAction, toJS } from 'mobx'
import { Avatar, Image, Tooltip } from 'antd'
import faker from 'faker'

import { getAuthToken } from '../utils/localStorage'
import * as api from '../api/skills'

import ApiRequest from './ApiRequest'
import SkillTableRow from './SkillTableRow'


export default class SkillStore {
  selectedSkill
  selectedSectionId

  columnsData = []

  skills = []

  skillsRequest
  scoreRequest
  employeesRequest
  departmentsRequest
  sectionsRequest
  groupsRequest

  saveScoreRequest
  getSkillRequest
  createSkillRequest
  editSkillRequest

  searchString = ''
  filters = {
    employees: [],
    empDepartments: [],
    skillDepartments: []
  }

  constructor(rootStore) {
    this._rootStore = rootStore
    makeAutoObservable(this)

    this.skillsRequest = new ApiRequest({
      apiFunction: api.getSkills,
      rootStore
    })

    this.scoreRequest = new ApiRequest({
      apiFunction: api.getSkillsScore,
      rootStore
    })

    this.employeesRequest = new ApiRequest({
      apiFunction: api.getSkillsEmployees,
      rootStore
    })

    this.departmentsRequest = new ApiRequest({
      apiFunction: api.getSkillsDepartments,
      rootStore
    })

    this.sectionsRequest = new ApiRequest({
      apiFunction: api.getSkillsSections,
      rootStore
    })

    this.groupsRequest = new ApiRequest({
      apiFunction: api.getSkillsGroups,
      rootStore
    })

    this.saveScoreRequest = new ApiRequest({
      apiFunction: api.postSkillsScore,
      rootStore
    })

    this.getSkillRequest = new ApiRequest({
      apiFunction: api.getSkill,
      rootStore
    })

    this.createSkillRequest = new ApiRequest({
      apiFunction: api.postSkill,
      rootStore
    })

    this.editSkillRequest = new ApiRequest({
      apiFunction: api.patchSkill,
      rootStore
    })

    autorun(() => {
      if (this.sectionsRequest.data && !this.skillsRequest.data) {
        this.selectSection(this.sectionsRequest.data?.items?.[0]?.id)
      }
    })

    // Generate rows for table (skills / scores)
    autorun(() => {
      if (this.skillsRequest.data?.count > 0 && this.employeesRequest.data?.count > 0 && this.scoreRequest.data) {
        const newSkills = []
        const employees = this.employeesRequest.data.items
        const scores = this.scoreRequest.data.items

        this.skillsRequest.data.items
          .filter((skill) => {
            let res = true
            if (this.searchString !== '') {
              res = res && skill.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
            }
            if (this.filters.skillDepartments.length > 0) {
              res = res && skill.departments.some(dep => this.filters.skillDepartments.includes(dep.id))
            }
            return res
          })
          .forEach((skill) => {
            const cells = {}
            employees.forEach((employee) => {
              const scoreItem = scores.find(item => item.skill === skill.id && item.employee === employee.id)
              cells[employee.id] = scoreItem ? scoreItem.score : 0
            })

            newSkills.push(new SkillTableRow(this, skill.id, skill.name, cells))
          })

        runInAction(() => {
          this.skills = newSkills
        })
      } else {
        runInAction(() => {
          this.skills = []
        })
      }
    })

    // Generate columns for table (employees)
    autorun(() => {
      if (this.employeesRequest.data?.count > 0) {
        runInAction(() => {
          this.columnsData = [
            {
              title: 'Название технологии',
              dataIndex: 'skillName',
              width: 150,
              fixed: 'left',
              // eslint-disable-next-line react/display-name
              render: (text, record, index) => (
                <div
                  onClick={() => {
                    this.getSkillRequest.send(record.key)
                  }}
                >
                  {text}
                </div>
              )
            },
            ...this.employeesRequest.data.items
              .filter((employee) => {
                let res = true

                const hasEmpDepartmentsFilter = this.filters.empDepartments.length > 0

                if (this.filters.employees.length > 0) {
                  res = res && this.filters.employees.includes(employee.id)

                  if (hasEmpDepartmentsFilter && employee.department) {
                    res = res || this.filters.empDepartments.includes(employee.department.id)
                  }
                } else if (hasEmpDepartmentsFilter) {
                  res =
                    res && (employee.department ? this.filters.empDepartments.includes(employee.department.id) : false)
                }

                return res
              })
              .map(employee => ({
                title: (
                  <Tooltip title={employee.username} placement="top">
                    <Avatar size={40} src={employee.avatar}>
                      {employee.username}
                    </Avatar>
                  </Tooltip>
                ),
                dataIndex: employee.id,
                width: 70,
                align: 'center',
                editable: true
              }))
          ]
        })
      }
    })

    autorun(() => {
      const skill = this.getSkillRequest.data

      if (skill && this.selectedSkill?.id !== skill.id) {
        this.selectSkill(skill)
      }
    })
  }

  get tableDataSource() {
    return this.skills.map(skill => ({ ...skill, ...skill.cells, cells: undefined }))
  }

  fetchInitData = () => {
    if (!getAuthToken()) return

    this.sectionsRequest.send()
    this.employeesRequest.send()
    this.groupsRequest.send()
    this.departmentsRequest.send()
  }

  handleSaveRow = (row) => {
    if (!this.skills && !this.skills.length < 1) return

    const skillIndex = this.skills.findIndex(item => row.key === item.key)
    if (skillIndex === -1) return

    const skill = this.skills[skillIndex]
    const cells = skill.cells

    for (const userId in cells) {
      const newScoreInt = parseInt(row[userId], 10)
      if (newScoreInt !== cells[userId]) {
        this.skills[skillIndex] = {
          ...skill,
          cells: {
            ...skill.cells,
            [userId]: newScoreInt
          }
        }

        this.saveScoreRequest.send({ skill: row.key, employee: userId, score: newScoreInt })
      }
    }
  }

  selectSkill = (skill) => {
    this.selectedSkill = skill
    if (skill) this._rootStore.uiStore.toggleSkillForm()
  }

  selectSection = (id = this.selectedSectionId) => {
    this.selectedSectionId = id
    this.skillsRequest.send({ section: id })
    this.scoreRequest.send({ section: id })
  }

  searchSkills = (text) => {
    this.searchString = text
    this.selectSection()
  }

  setFilters = (filters) => {
    this.filters = { ...filters }
    this.employeesRequest.send()
  }
}
