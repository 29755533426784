import { http } from '../services/httpClient'

/*
section * integer(query)	
department integer(query)	
limit integer(query)	
offset integer(query)	
*/
export const getSkills = params => http.get('/api/skills', { params: { ...params } })

/*
{
  "name": "string",
  "section": 0,
  "group": 0,
  "departments": [
    0
  ],
  "description": "string"
}
*/
export const postSkill = body => http.post('/api/skills', body)

/*
section * integer(query)	
department integer(query)	
limit integer(query)	
offset integer(query)	
*/
export const getSkillsScore = params => http.get('/api/skills/score', { params: { ...params } })

/*
{
  "skill": 0,
  "employee": "string id",
  "score": 0
}
*/
export const postSkillsScore = body =>
  http.post('/api/skills/score', {
    items: [body]
  })

export const getSkill = id => http.get(`/api/skills/${id}`)

/*
{
  "name": "string",
  "description": "string",
  "section": 0,
  "group": 0,
  "departments": [
    0
  ]
}
*/
export const patchSkill = ({ id, body }) => http.patch(`/api/skills/${id}`, body)
export const deleteSkill = id => http.delete(`/api/skills/${id}`)

export const getSkillsEmployees = () => http.get('/api/skills/employees')
export const getSkillsDepartments = () => http.get('/api/skills/departments')
export const getSkillsSections = () => http.get('/api/skills/sections')
export const getSkillsGroups = section => http.get('/api/skills/groups', section ? { params: { section } } : undefined)
