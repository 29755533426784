import axios from 'axios'

import config from '../consts/appConfig'
import { setAuthToken, getAuthToken } from '../utils/localStorage'


const authToken = getAuthToken()

export const http = axios.create({
  baseURL: config.api.baseUrl,
  headers: {
    SECRET: config.api.secret,
    AUTHENTICATION: `JWT ${authToken}`
  }
})

export const setToken = (token) => {
  setAuthToken(token)
  http.defaults.headers['AUTHENTICATION'] = `JWT ${token}`
}
