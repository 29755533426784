import React, { useEffect } from 'react'
import { Form, Input, Select, Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import { useRootStore } from '../../hooks/useStore'


const { Option } = Select

const SkillForm = observer(() => {
  const [form] = Form.useForm()

  const {
    uiStore: { isSkillFormVisible, toggleSkillForm },
    skillStore: {
      selectedSkill,
      sectionsRequest,
      groupsRequest,
      departmentsRequest,
      createSkillRequest,
      selectSection,
      editSkillRequest,
      selectSkill
    }
  } = useRootStore()
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  useEffect(() => {
    if (!selectedSkill) return

    form?.setFieldsValue({
      ...selectedSkill,
      section: selectedSkill.section.id,
      group: selectedSkill.group.id,
      departments: selectedSkill.departments.map(dep => dep.id)
    })
  }, [selectedSkill, form])

  useEffect(() => {
    if ((createSkillRequest.data || editSkillRequest.data) && confirmLoading) {
      setConfirmLoading(false)
      toggleSkillForm()
      selectSkill(undefined)
      form?.resetFields()
      selectSection()
    }
  }, [
    createSkillRequest.data,
    editSkillRequest.data,
    confirmLoading,
    toggleSkillForm,
    selectSection,
    form,
    selectSkill
  ])

  const handleOk = () => {
    setConfirmLoading(true)

    if (!selectedSkill) createSkillRequest.send(form.getFieldsValue())
    else editSkillRequest.send({ id: selectedSkill.id, body: form.getFieldsValue() })
  }

  const handleCancel = () => {
    toggleSkillForm()
    selectSkill(undefined)
    form?.resetFields()
  }

  if (!sectionsRequest.data || !groupsRequest.data || !departmentsRequest.data) return null

  return (
    <Modal
      title={selectedSkill?.id ? 'ТЕХНОЛОГИЯ' : 'НОВАЯ ТЕХНОЛОГИЯ'}
      visible={isSkillFormVisible}
      okText="Сохранить"
      cancelText="Отмена"
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Название технологии" name="name" rules={[{ required: true }]}>
          <Input placeholder="Введите название" />
        </Form.Item>

        <Form.Item label="Категория" name="section" rules={[{ required: true }]}>
          <Select placeholder="Выбрать категорию">
            {sectionsRequest.data.items.map(section => (
              <Option key={section.id} value={section.id}>
                {section.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Подкатегория" name="group" rules={[{ required: true }]}>
          <Select placeholder="Выбрать подкатегорию">
            {groupsRequest.data.items.map(group => (
              <Option key={group.id} value={group.id}>
                {group.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Комментарий" name="description">
          <Input.TextArea placeholder="Опишите технологию" />
        </Form.Item>

        <Form.Item label="К каким отделам относится технология" name="departments" rules={[{ required: true }]}>
          <Select mode="multiple" placeholder="Выберите отделы" allowClear optionFilterProp="key">
            {departmentsRequest.data.items.map(department => (
              <Option key={department.name} value={department.id}>
                {department.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
})

export default SkillForm
