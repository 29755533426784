import styled from 'styled-components'


export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding: 10px 20px;
`
