import styled from 'styled-components'


export const Technologies = styled.div`
  display: flex;
  width: '100%';
  align-items: center;
  justify-content: space-between;
`

export const ActionsBar = styled.div`
  display: flex;
  width: '100%';
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const TableContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`
