import { runInAction, makeAutoObservable } from 'mobx'
import { notification } from 'antd'

import { getErrorMessage } from '../utils/apiErrors'


export default class ApiRequest {
  apiFunction
  excludedErrorCodes
  disableGlobalErrors = false
  shouldUseCustomErrorMessage = false

  errors = undefined
  isLoading = false
  data = undefined

  constructor({
    rootStore = undefined,
    apiFunction,
    excludedErrorCodes = [],
    disableGlobalErrors = false,
    shouldUseCustomErrorMessage = false
  }) {
    this.rootStore = rootStore
    this.apiFunction = apiFunction
    this.excludedErrorCodes = excludedErrorCodes
    this.disableGlobalErrors = disableGlobalErrors
    this.shouldUseCustomErrorMessage = shouldUseCustomErrorMessage

    makeAutoObservable(this)
  }

  setData = (data) => {
    this.data = data
  }

  setErrors = (errors) => {
    this.errors = errors
  }

  clearErrors = () => {
    this.errors = undefined
  }

  send = (requestData) => {
    this.isLoading = true

    return this.apiFunction(requestData)
      .then((response) => {
        this.setData(response.data)
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null

        if (statusCode) {
          if (statusCode === 403) this.rootStore?.uiStore.showLoginForm()

          const reponseErrors = error.response?.data?.errors
          error.response && this.setErrors(reponseErrors)

          // Start global error handler
          if (!this.disableGlobalErrors && this.excludedErrorCodes?.indexOf(statusCode) === -1) {
            // global error handler logic
            const customMessage = getErrorMessage(statusCode)

            let alertDescription = this.shouldUseCustomErrorMessage ? customMessage : error.message
            if (reponseErrors) {
              alertDescription = reponseErrors[0]?.message
            }

            notification['error']({
              message: 'Error',
              description: alertDescription
            })
          }
        } else {
          console.error(error)
        }
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false
        })
      })
  }
}
