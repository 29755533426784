import { makeAutoObservable, runInAction } from 'mobx'


export default class UiStore {
  isSkillFormVisible = false
  isLoginFormVisible = false

  constructor(rootStore) {
    this._rootStore = rootStore
    makeAutoObservable(this)
  }

  toggleSkillForm = () => {
    runInAction(() => {
      this.isSkillFormVisible = !this.isSkillFormVisible
    })
  }

  showLoginForm = () => {
    this.isLoginFormVisible = true
  }

  hideLoginForm = () => {
    this.isLoginFormVisible = false
  }
}
