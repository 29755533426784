import config from '../consts/appConfig'


const authTokenKey = config.localStorage.authTokenStorageName

// AuthToken
export const getAuthToken = () => localStorage.getItem(authTokenKey)

export const setAuthToken = (token) => {
  localStorage.setItem(authTokenKey, token)
}

export const removeAuthToken = () => {
  localStorage.removeItem(authTokenKey)
}
